import React, { ChangeEvent, FormEvent } from 'react'
import { Alert, Box, Button, FormControlLabel, FormGroup, Link, TextField, Typography } from '@mui/material'
import { useAppDispatch } from '@hooks'
import { showGenericErrorSnackbar } from '@slices/snackbars'
import { LoadingButton } from '@mui/lab'
import { setUser } from '../src/helpers/storage/users'
import { Checkbox } from 'morsum-web-ui-kit'
import { useRouter } from 'next/router'
import AuthenticationLayout from '@components/Layouts/AuthenticationLayout'
import { GetServerSideProps } from 'next'
import axios from 'axios'
import defaultHeader from 'src/helpers/defaultHeader'
import Image from 'next/image'
import PoweredByImage from '@assets/PoweredBy.svg'
import { Vendor, VendorsResponse } from 'gwy-sdk'
import { setVendor } from '@slices/vendor'
import { useLoginMutation } from '@api/auth'
import { useIdentify } from '@hooks/events/useIdentify'
import errors from 'src/constants/errors'
import { useShutDown } from '@hooks/useShutDown'

const Login: React.FC<{ customVendor: Vendor }> = ({ customVendor }) => {
    const router = useRouter()
    const dispatch = useAppDispatch()

    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [rememberMe, setRememberMe] = React.useState<boolean>(false)
    const [login, { isLoading }] = useLoginMutation()

    const { identify } = useIdentify()
    const { hideLogin, hostConfig } = useShutDown()

    React.useEffect(() => {
        dispatch(setVendor(customVendor))
    }, [customVendor, dispatch])

    const handleRememberMeChange = () => {
        setRememberMe((prev) => !prev)
    }

    const handleEmailOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const handlePasswordOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handleFormOnSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (hideLogin) {
            alert('Unable to login')
            return
        }

        if (window.HubSpotConversations !== undefined) {
            window.HubSpotConversations.clear()
            window.HubSpotConversations.widget.remove()
        }

        login({
            email,
            password
        })
            .unwrap()
            .then((res) => {
                setUser(res.response)
                identify(res.response)
                router.push('/dashboard')
            })
            .catch((error) => {
                dispatch(showGenericErrorSnackbar(error.data ? error.data.error.message : errors.UNRESPONSIVE_API))
            })
    }

    const handleForgotPasswordButton = () => {
        router.push('/forgot-password')
    }

    return (
        <AuthenticationLayout customVendor={customVendor}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {hostConfig && (
                    <Alert
                        severity={'warning'}
                        sx={{
                            m: 1
                        }}
                    >
                        {hostConfig.message}
                    </Alert>
                )}

                {!hideLogin && (
                    <form
                        style={{
                            fontSize: '15px',
                            width: 440,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: '24px'
                        }}
                        onSubmit={handleFormOnSubmit}
                    >
                        <Typography
                            sx={{ marginBottom: '20px' }}
                            variant={'h4'}
                        >
                            Welcome to {customVendor ? customVendor.name : 'Anchovi'}
                        </Typography>

                        <TextField
                            id={'emailField'}
                            label={'Email'}
                            variant={'outlined'}
                            fullWidth={true}
                            defaultValue={email}
                            onChange={handleEmailOnChange}
                            inputProps={{
                                autoComplete: 'username'
                            }}
                            className="fs-mask"
                        />
                        <TextField
                            id={'passwordField'}
                            label={'Password'}
                            variant={'outlined'}
                            fullWidth={true}
                            defaultValue={password}
                            onChange={handlePasswordOnChange}
                            type={'password'}
                            inputProps={{
                                autoComplete: 'password'
                            }}
                        />

                        <LoadingButton
                            loading={isLoading}
                            variant={'contained'}
                            fullWidth={true}
                            size="large"
                            color={'primary'}
                            type={'submit'}
                        >
                            Sign in
                        </LoadingButton>

                        <FormGroup
                            sx={{
                                display: 'none'
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rememberMe}
                                        onChange={handleRememberMeChange}
                                    />
                                }
                                label="Remember me"
                            />
                        </FormGroup>

                        <Button
                            variant={'text'}
                            color={'primary'}
                            size="large"
                            onClick={handleForgotPasswordButton}
                        >
                            Forgot password?
                        </Button>

                        <Typography
                            variant={'body2'}
                            sx={{
                                textAlign: 'left'
                            }}
                        >
                            Please contact your sales representative or customer support team to register for access.
                        </Typography>

                        <Typography
                            variant={'body2'}
                            sx={{
                                textAlign: 'left'
                            }}
                        >
                            By tapping Sign in, you agree to the
                            <Link
                                href={'/terms-and-conditions'}
                                sx={{ textDecoration: 'underline' }}
                            >
                                {' '}
                                Terms and conditions.{' '}
                            </Link>
                        </Typography>
                    </form>
                )}

                <Box sx={{ mt: 4 }}>
                    <Image
                        src={PoweredByImage}
                        alt={'anchovi-logo'}
                    />
                </Box>
            </Box>
        </AuthenticationLayout>
    )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    const defaultHeaders = defaultHeader()
    let host = context.req?.headers?.host

    try {
        let protocol = ''
        const upgUrl = process.env.NEXT_PUBLIC_UPG_URL || ''
        const indexOf = upgUrl.indexOf('//')
        if (indexOf !== -1) {
            protocol = upgUrl.substring(0, indexOf + 2)
        }

        host = host?.split('.').slice(0, 3).join('.')
        const origin = `${protocol}${host}`

        const customInstance = axios.create({
            baseURL: process.env.NEXT_PUBLIC_UPG_URL,
            headers: {
                siteGUID: process.env.NEXT_PUBLIC_SITE_GUID,
                deviceType: defaultHeaders.deviceType,
                deviceId: defaultHeaders.deviceId,
                pushId: defaultHeaders.pushId,
                gmt: defaultHeaders.gmt,
                origin
            }
        })

        const customVendor = await customInstance.get<VendorsResponse>('wl/vendors/origins')

        return {
            props: {
                customVendor: customVendor.data.response
            }
        }
    } catch (error) {
        return {
            props: {
                vendorInfo: null
            }
        }
    }
}

export default Login
