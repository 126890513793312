import React, { FC } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import { Header } from 'morsum-web-ui-kit'
import Image, { StaticImageData } from 'next/image'
import { Box } from '@mui/material'
import { Vendor } from 'gwy-sdk'
import SWLogo from '@assets/s-wfoods.png'
import login1 from '@assets/login1.png'
import login2 from '@assets/login2.png'
import login3 from '@assets/login3.png'
import login4 from '@assets/login4.png'

interface AuthenticationLayoutProps {
    customVendor?: Vendor
    children?: React.ReactNode
}

const SWDomains = ['https://s-wfoods.ancho.vi/', 'https://sandwstg.morsum.co/', 'https://sandwdev.morsum.co/']

const AuthenticationLayout: FC<AuthenticationLayoutProps> = ({ children, customVendor }) => {
    const imgRef = React.useRef<StaticImageData>()
    const imageMap = [login1, login2, login3, login4]

    const randomImage = () => {
        const index = Math.floor(Math.random() * imageMap.length)
        return imageMap[index]
    }

    React.useEffect(() => {
        if (SWDomains.includes(window.location.href)) {
            imgRef.current = SWLogo
        } else {
            imgRef.current = randomImage()
        }
    }, [])

    return (
        <React.Fragment>
            <Header
                logo={
                    customVendor && (
                        <Box
                            sx={{
                                display: 'flex'
                            }}
                        >
                            <Image
                                src={customVendor?.imageUrl || ''}
                                height={64}
                                width={64}
                                alt={'vendor-logo'}
                            />
                        </Box>
                    )
                }
            />
            <Grid
                container
                component="main"
                sx={{ height: 'calc(100vh - 72px)' }}
            >
                <CssBaseline />
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    style={{ backgroundColor: '#FFFFFF', display: 'flex', justifyContent: 'center' }}
                >
                    {children}
                </Grid>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={6}
                    sx={{
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundImage: imgRef.current ? `url(${imgRef.current.src})` : ''
                    }}
                />
            </Grid>
        </React.Fragment>
    )
}

export default AuthenticationLayout
