import { shutDownConfig } from '../constants/shutDownConfig'
import moment from 'moment/moment'

export const useShutDown = () => {
    const host = window.location.hostname
    const hostConfig = shutDownConfig[host]

    let hideLogin = false

    if (hostConfig) {
        const offDate = moment(hostConfig.shutdownDate)
        const now = moment()
        if (offDate.isBefore(now)) {
            hideLogin = true
        }
    }

    return { hostConfig, hideLogin }
}
