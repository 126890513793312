import { User } from 'gwy-sdk'
import { getCookieName, setCookie } from '../../cookies'

export function setUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user))
    setAuthCookie(user.accessToken.token)
}

const setAuthCookie = (token: string | undefined) => {
    const tokenCookieName = getCookieName('morsum_sso')
    setCookie({
        name: tokenCookieName,
        value: String(token),
        path: '/',
        domain: process.env.NEXT_PUBLIC_APP_DOMAIN,
        secure: true
    })
}
