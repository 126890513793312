interface Config {
    message: string
    shutdownDate: string
}

export const shutDownConfig: Record<string, Config> = {
    'ancho.vi': {
        message:
            'NOTICE: This website is being retired on Friday, July 12, 2024. After that date, you will no longer be able to log in. Please contact your distributor or email us at support@ancho.vi for direction on how to place your orders after Friday, July 12, 2024. Thank you for your business.',
        shutdownDate: '2024-07-26'
    },
    'totalfoods.ancho.vi': {
        message:
            'NOTICE: This website is being retired on Friday, July 12, 2024. After that date, you will no longer be able to log in. Please contact your Total Foods rep or call us at 414-881-0959 for direction on how to place your orders after Friday, July 12, 2024. Thank you for your business.',
        shutdownDate: '2024-07-12'
    },
    'wohrles.ancho.vi': {
        message:
            "NOTICE: This website is being retired on Friday, July 12, 2024. After that date, you will no longer be able to log in. Please contact your Wohrle's rep or call us at (413) 442-1518 for direction on how to place your orders after Friday, July 12, 2024. Thank you for your business.",
        shutdownDate: '2024-07-12'
    },
    'osbornbros.ancho.vi': {
        message:
            'NOTICE: This website is being retired on Friday, July 12, 2024. After that date, you will no longer be able to log in. Please contact your Osborn rep or call us at 256-547-8601 for direction on how to place your orders after Friday, July 12, 2024. Thank you for your business.',
        shutdownDate: '2024-07-12'
    },
    'cotati.ancho.vi': {
        message:
            'NOTICE: This website is being retired on Friday, July 12, 2024. After that date, you will no longer be able to log in. Please contact your Cotati Foodservice rep or call us at (707) 795-4489 for direction on how to place your orders after Friday, July 12, 2024. Thank you for your business.',
        shutdownDate: '2024-07-12'
    },
    'melotte.ancho.vi': {
        message:
            'NOTICE: This website is being retired on Friday, June 21, 2024. After that date, you will no longer be able to log in. Please contact your Melotte rep or call us at 920-435-1986 for direction on how to place your orders after Friday, June 21, 2025. Thank you for your business.',
        shutdownDate: '2024-06-21'
    },
    'cdfoodservice.ancho.vi': {
        message:
            'NOTICE: This website is being retired on Friday, June 21, 2024. After that date, you will no longer be able to log in. Please contact your CD Foods rep or call us at (519) 837-0074 for direction on how to place your orders after Friday, June 21, 2024. Thank you for your business.',
        shutdownDate: '2024-06-21'
    },
    'sandwstg.morsum.co': {
        message:
            'NOTICE: This website is being retired on Friday, June 21, 2024. After that date, you will no longer be able to log in. Please contact your CD Foods rep or call us at (519) 837-0074 for direction on how to place your orders after Friday, June 21, 2024. Thank you for your business.',
        shutdownDate: '2024-06-21'
    },
    'osbornestg.morsum.co': {
        message:
            'NOTICE: This website is being retired on Friday, June 21, 2024. After that date, you will no longer be able to log in. Please contact your CD Foods rep or call us at (519) 837-0074 for direction on how to place your orders after Friday, June 21, 2024. Thank you for your business.',
        shutdownDate: '2024-07-12'
    },
    'kaleelbrothers.ancho.vi': {
        message:
            'NOTICE: This website is being retired on Friday, July 12, 2024. After that date, you will no longer be able to log in. Please contact your Kaleel Brothers rep or call us at (330) 758-0861 for direction on how to place your orders after Friday, July 12, 2024. Thank you for your business.',
        shutdownDate: '2024-07-12'
    }
}
